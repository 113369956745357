import Splide from '@splidejs/splide';
require("@splidejs/splide/dist/css/splide-core.min.css");

// https://github.com/hsnaydd/moveTo
import MoveTo from 'moveto';

// NAVIGATION
const body = document.querySelector('body');
const mainHeader = document.querySelector('.main-header');
let scrollTop = scrollPosition();
let windowWidth = window.innerWidth;

// checkWindowWidth();

function scrollPosition() {
	return window.scrollY || document.documentElement.scrollTop || 0;
}

if (scrollTop >= 3) {
	if (mainHeader.classList.contains('main-header--offset')) {
		mainHeader.classList.remove('main-header--offset');
	}
}
if (windowWidth <= 992) {
	body.classList.remove('mt-0');
}

window.addEventListener('scroll', function(event) {
	scrollTop = scrollPosition();
	if (scrollTop >= 3) {
		if (mainHeader.classList.contains('main-header--offset')) {
			mainHeader.classList.remove('main-header--offset');
			if (windowWidth <= 992) {
				body.classList.remove('mt-lg-0');
			}
		}
	} else {
		if (windowWidth >= 992) {
			mainHeader.classList.add('main-header--offset');
			// body.classList.add('mt-0');
		}
	}
}, { passive: true });

window.addEventListener('resize', function(event) {
	windowWidth = window.innerWidth;
	checkWindowWidth();
}, { passive: true });

function checkWindowWidth() {
	if (windowWidth < 992) {
		// console.log(windowWidth < 992);
		// console.log('yawn');
		if (mainHeader.classList.contains('main-header--offset')) {
			mainHeader.classList.add('main-header--offset');
			if (windowWidth <= 992) {
				body.classList.remove('mt-0');
			}
		}
	} else {
		if (scrollTop <= 3) {
			mainHeader.classList.add('main-header--offset');
			body.classList.add('mt-0');
		}
	}
}


// FULL BANNER
// we don't want the video to load on mobile, so we added a data-src and on the proper window width we add a src to avctivate the video
if (windowWidth >= 992) {
	const bannerVideo = document.querySelector('.banner-full__video');
	const bannerVideoSource = document.querySelector('.banner-full__video source');
	bannerVideoSource.setAttribute('src', bannerVideoSource.getAttribute('data-src'));
	bannerVideo.load();
}

const bannerScrollButton = document.querySelector('.banner-full__scroller');
let bannerScrollButtonPosition = bannerScrollButton.scrollTop;

const moveTo = new MoveTo({
	// tolerance: mainHeader.offsetHeight,
});

if (bannerScrollButton) {
	bannerScrollButton.addEventListener('click', bannerScrollButtonHandler, { passive: true });
}

function bannerScrollButtonHandler() {
	const scrollToLocation = document.querySelector('#bannerScrollTo');
	moveTo.move(scrollToLocation);
}


const activitySlider = document.querySelector('#activitySlider');
// const gliderInit = new Glider(gliderWrapper);

if (activitySlider) {
	const slider = new Splide('#activitySlider', {
		type   : 'loop',
		autoplay: true,
		perPage: 3,
		perMove: 1,
		pagination: false,
		breakpoints: {
			768: {
				perPage: 2,
			},
			568: {
				perPage: 1,
			},
		}
	});
	slider.mount();
}